.style1-style1 {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.style1-header {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  position: relative;
  max-width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.style1-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.style1-store-banner {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.style1-root-class-name {
  margin: var(--dl-space-space-halfunit);
}
