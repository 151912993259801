.config-container {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.config-header-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.config-header {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  position: relative;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.config-mobile-menu {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  z-index: 101;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.config-top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: space-between;
}
.config-logo {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.config-image {
  width: 100px;
  object-fit: cover;
}
.config-close-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.config-icon {
  width: 24px;
  height: 24px;
}
.config-mid {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.config-text {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.config-text:hover {
  color: var(--dl-color-turquoise-default);
}
.config-text01 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.config-text01:hover {
  color: var(--dl-color-turquoise-default);
}
.config-text02 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.config-text02:hover {
  color: var(--dl-color-turquoise-default);
}
.config-text03 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.config-text03:hover {
  color: var(--dl-color-turquoise-default);
}
.config-text04 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.config-text04:hover {
  color: var(--dl-color-turquoise-default);
}
.config-text05 {
  transition: 0.3s;
}
.config-text05:hover {
  color: var(--dl-color-turquoise-default);
}
.config-bot {
  flex: 0 0 auto;
  display: flex;
  margin-top: auto;
  align-items: flex-start;
  flex-direction: column;
}
.config-container01 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.config-link {
  display: contents;
}
.config-container02 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.config-icon02 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.config-icon02:hover {
  fill: var(--dl-color-purple-default);
}
.config-link1 {
  display: contents;
}
.config-container03 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.config-icon04 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.config-icon04:hover {
  fill: var(--dl-color-pink-default);
}
.config-link2 {
  display: contents;
}
.config-container04 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.config-icon06 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.config-icon06:hover {
  fill: var(--dl-color-turquoise-default);
}
.config-link3 {
  display: contents;
}
.config-container05 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.config-icon08 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.config-icon08:hover {
  fill: var(--dl-color-orange-default);
}
.config-main {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.config-blur-background {
  top: 0px;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: auto;
  filter: blur(60px);
  height: 70vh;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  background-color: #f2f2f2;
}
.config-hero {
  flex: 0 0 auto;
  width: 100%;
  height: 80vh;
  display: flex;
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: 0px;
  justify-content: space-between;
}
.config-container06 {
  flex: auto;
  height: auto;
  display: flex;
  position: relative;
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.config-text06 {
  font-size: 72px;
  text-align: left;
  font-family: Inter;
  font-weight: 800;
  border-radius: var(--dl-radius-radius-radius4);
  margin-bottom: var(--dl-space-space-doubleunit);
  text-transform: none;
  text-decoration: none;
}
.config-container07 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.config-container08 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.config-container09 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.config-textinput5 {
  width: 50%;
}
.config-container10 {
  flex: 0 0 auto;
  width: 100%;
  /* border: 2px dashed rgba(120, 120, 120, 0.4); */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  transition: 0.3s;
  border-radius: 5px;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.config-clients {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 100;
  overflow: hidden;
  position: relative;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
}
.config-divider {
  top: 0px;
  left: auto;
  right: auto;
  width: 100%;
  bottom: auto;
  height: 1px;
  position: absolute;
  background: linear-gradient(
    90deg,
    rgba(192, 204, 218, 0.1) 0%,
    rgba(192, 204, 218, 0.6) 50.38%,
    rgba(192, 204, 218, 0.1) 100%
  );
}
.config-image1 {
  width: 100px;
  object-fit: cover;
}
.config-image2 {
  width: 100px;
  object-fit: cover;
}
.config-image3 {
  width: 100px;
  object-fit: cover;
}
.config-image4 {
  width: 100px;
  object-fit: cover;
}
.config-image5 {
  width: 100px;
  object-fit: cover;
}
.config-divider1 {
  top: auto;
  left: auto;
  right: auto;
  width: 100%;
  bottom: 0px;
  height: 1px;
  position: absolute;
  background: linear-gradient(
    90deg,
    rgba(192, 204, 218, 0.1) 0%,
    rgba(192, 204, 218, 0.6) 50.38%,
    rgba(192, 204, 218, 0.1) 100%
  );
}
.config-features {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.config-text14 {
  max-width: 500px;
  text-align: center;
}
.config-text15 {
  white-space: pre-wrap;
}
.config-features1 {
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.config-testimonials {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: center;
  background-color: var(--dl-color-turquoise-100);
}
.config-container11 {
  display: flex;
  flex-wrap: wrap;
  max-width: 1100px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.config-testimonial {
  width: 100%;
  display: flex;
  justify-content: center;
}
.config-container12 {
  width: 100%;
  display: flex;
  padding: 48px;
  max-width: 1400px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.config-text17 {
  max-width: 500px;
  text-align: center;
}
.config-text18 {
  white-space: pre-wrap;
}
.config-container13 {
  width: 100%;
  display: grid;
  grid-gap: 32px;
  padding-top: 20px;
  grid-template-columns: 1fr 1fr 1fr;
}
.config-achievements {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.config-container14 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.config-text20 {
  white-space: pre-wrap;
}
.config-text24 {
  white-space: pre-wrap;
}
.config-container15 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.config-container16 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.config-container17 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.config-footer {
  flex: 0 0 auto;
  color: var(--dl-color-gray-white);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.config-container18 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.config-image6 {
  object-fit: cover;
}
.config-container19 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.config-text25 {
  color: var(--dl-color-gray-300);
  margin-top: var(--dl-space-space-unit);
}
.config-container20 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.config-link4 {
  display: contents;
}
.config-container21 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.config-icon10 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.config-icon10:hover {
  fill: var(--dl-color-purple-default);
}
.config-link5 {
  display: contents;
}
.config-container22 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.config-icon12 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.config-icon12:hover {
  fill: var(--dl-color-pink-default);
}
.config-link6 {
  display: contents;
}
.config-container23 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.config-icon14 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.config-icon14:hover {
  fill: var(--dl-color-turquoise-default);
}
.config-link7 {
  display: contents;
}
.config-container24 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.config-icon16 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.config-icon16:hover {
  fill: var(--dl-color-orange-default);
}
@media (max-width: 991px) {
  .config-hero {
    height: auto;
    align-self: center;
    flex-direction: column;
  }
  .config-container06 {
    align-items: center;
    margin-bottom: var(--dl-space-space-doubleunit);
    flex-direction: column;
  }
  .config-container07 {
    align-self: center;
    flex-direction: column;
  }
  .config-container11 {
    flex-direction: column;
  }
  .config-container13 {
    grid-template-columns: 1fr;
  }
  .config-achievements {
    flex-direction: column;
  }
  .config-container15 {
    margin-top: var(--dl-space-space-doubleunit);
  }
}
@media (max-width: 767px) {
  .config-hero {
    flex-direction: column;
  }
  .config-container07 {
    align-self: center;
    flex-direction: column;
  }
  .config-clients {
    justify-content: center;
  }
  .config-image1 {
    margin: var(--dl-space-space-unit);
  }
  .config-image2 {
    margin: var(--dl-space-space-unit);
  }
  .config-image3 {
    margin: var(--dl-space-space-unit);
  }
  .config-image4 {
    margin: var(--dl-space-space-unit);
  }
  .config-image5 {
    margin: var(--dl-space-space-unit);
  }
  .config-text13 {
    font-size: 40px;
  }
  .config-container12 {
    padding-left: 32px;
    padding-right: 32px;
  }
  .config-text16 {
    font-size: 40px;
  }
  .config-text19 {
    font-size: 40px;
  }
  .config-container19 {
    flex-direction: column;
  }
  .config-text25 {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
}
@media (max-width: 479px) {
  .config-bot {
    width: 100%;
  }
  .config-container08 {
    margin-top: var(--dl-space-space-doubleunit);
  }
  .config-container09 {
    margin-top: var(--dl-space-space-doubleunit);
  }
  .config-container10 {
    margin-top: var(--dl-space-space-doubleunit);
  }
  .config-text13 {
    text-align: center;
  }
  .config-text14 {
    text-align: center;
  }
  .config-container12 {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .config-text16 {
    text-align: center;
  }
  .config-text17 {
    text-align: center;
  }
  .config-container14 {
    align-items: center;
  }
  .config-text19 {
    text-align: center;
  }
  .config-text23 {
    text-align: center;
  }
  .config-container16 {
    flex-direction: column;
  }
  .config-container17 {
    flex-direction: column;
  }
}
