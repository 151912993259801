.logo-logo {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.logo-image {
  width: 50px;
  object-fit: cover;
}
