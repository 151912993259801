.home-container {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-header-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-header {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  position: relative;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-mobile-menu {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  z-index: 101;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: space-between;
}
.home-logo {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-image {
  width: 100px;
  object-fit: cover;
}
.home-close-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-icon {
  width: 24px;
  height: 24px;
}
.home-mid {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text01 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text01:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text02 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text02:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text03 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text03:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text04 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text04:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text05 {
  transition: 0.3s;
}
.home-text05:hover {
  color: var(--dl-color-turquoise-default);
}
.home-bot {
  flex: 0 0 auto;
  display: flex;
  margin-top: auto;
  align-items: flex-start;
  flex-direction: column;
}
.home-container01 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-link {
  display: contents;
}
.home-container02 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon02 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon02:hover {
  fill: var(--dl-color-purple-default);
}
.home-link1 {
  display: contents;
}
.home-container03 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon04 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon04:hover {
  fill: var(--dl-color-pink-default);
}
.home-link2 {
  display: contents;
}
.home-container04 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon06 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon06:hover {
  fill: var(--dl-color-turquoise-default);
}
.home-link3 {
  display: contents;
}
.home-container05 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon08 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon08:hover {
  fill: var(--dl-color-orange-default);
}
.home-main {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.home-blur-background {
  top: 0px;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: auto;
  filter: blur(60px);
  height: 70vh;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  background-color: #f2f2f2;
}
.home-hero {
  flex: 0 0 auto;
  width: 100%;
  height: 75vh;
  display: flex;
  z-index: 100;
  max-width: 1110px;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: 0px;
}
.home-container06 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text06 {
  width: 1037px;
  text-align: center;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-image1 {
  flex: 1;
  height: 377px;
  align-self: center;
  object-fit: cover;
}
.home-clients {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 100;
  overflow: hidden;
  position: relative;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
}
.home-divider {
  top: 0px;
  left: auto;
  right: auto;
  width: 100%;
  bottom: auto;
  height: 1px;
  position: absolute;
  background: linear-gradient(
    90deg,
    rgba(192, 204, 218, 0.1) 0%,
    rgba(192, 204, 218, 0.6) 50.38%,
    rgba(192, 204, 218, 0.1) 100%
  );
}
.home-image2 {
  width: 100px;
  object-fit: cover;
}
.home-image3 {
  width: 100px;
  object-fit: cover;
}
.home-image4 {
  width: 100px;
  object-fit: cover;
}
.home-image5 {
  width: 100px;
  object-fit: cover;
}
.home-image6 {
  width: 100px;
  object-fit: cover;
}
.home-divider1 {
  top: auto;
  left: auto;
  right: auto;
  width: 100%;
  bottom: 0px;
  height: 1px;
  position: absolute;
  background: linear-gradient(
    90deg,
    rgba(192, 204, 218, 0.1) 0%,
    rgba(192, 204, 218, 0.6) 50.38%,
    rgba(192, 204, 218, 0.1) 100%
  );
}
.home-features {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text09 {
  max-width: 500px;
  text-align: center;
}
.home-text10 {
  white-space: pre-wrap;
}
.home-features1 {
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-testimonials {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: center;
  background-color: var(--dl-color-turquoise-100);
}
.home-container07 {
  display: flex;
  flex-wrap: wrap;
  max-width: 1100px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-testimonial {
  width: 100%;
  display: flex;
  justify-content: center;
}
.home-container08 {
  width: 100%;
  display: flex;
  padding: 48px;
  max-width: 1400px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text12 {
  max-width: 500px;
  text-align: center;
}
.home-text13 {
  white-space: pre-wrap;
}
.home-container09 {
  width: 100%;
  display: grid;
  grid-gap: 32px;
  padding-top: 20px;
  grid-template-columns: 1fr 1fr 1fr;
}
.home-achievements {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-container10 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text15 {
  white-space: pre-wrap;
}
.home-text19 {
  white-space: pre-wrap;
}
.home-container11 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-container12 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-container13 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-footer {
  flex: 0 0 auto;
  color: var(--dl-color-gray-white);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.home-container14 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-image7 {
  object-fit: cover;
}
.home-container15 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-text20 {
  color: var(--dl-color-gray-300);
  margin-top: var(--dl-space-space-unit);
}
.home-container16 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-link4 {
  display: contents;
}
.home-container17 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon10 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon10:hover {
  fill: var(--dl-color-purple-default);
}
.home-link5 {
  display: contents;
}
.home-container18 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon12 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon12:hover {
  fill: var(--dl-color-pink-default);
}
.home-link6 {
  display: contents;
}
.home-container19 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon14 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon14:hover {
  fill: var(--dl-color-turquoise-default);
}
.home-link7 {
  display: contents;
}
.home-container20 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon16 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon16:hover {
  fill: var(--dl-color-orange-default);
}
@media (max-width: 991px) {
  .home-hero {
    height: auto;
    flex-direction: column;
  }
  .home-container06 {
    align-items: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .home-text06 {
    text-align: center;
  }
  .home-container07 {
    flex-direction: column;
  }
  .home-container09 {
    grid-template-columns: 1fr;
  }
  .home-achievements {
    flex-direction: column;
  }
  .home-container11 {
    margin-top: var(--dl-space-space-doubleunit);
  }
}
@media (max-width: 767px) {
  .home-text06 {
    width: auto;
    font-size: 50px;
  }
  .home-clients {
    justify-content: center;
  }
  .home-image2 {
    margin: var(--dl-space-space-unit);
  }
  .home-image3 {
    margin: var(--dl-space-space-unit);
  }
  .home-image4 {
    margin: var(--dl-space-space-unit);
  }
  .home-image5 {
    margin: var(--dl-space-space-unit);
  }
  .home-image6 {
    margin: var(--dl-space-space-unit);
  }
  .home-text08 {
    font-size: 40px;
  }
  .home-container08 {
    padding-left: 32px;
    padding-right: 32px;
  }
  .home-text11 {
    font-size: 40px;
  }
  .home-text14 {
    font-size: 40px;
  }
  .home-container15 {
    flex-direction: column;
  }
  .home-text20 {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
}
@media (max-width: 479px) {
  .home-bot {
    width: 100%;
  }
  .home-text06 {
    width: auto;
  }
  .home-text08 {
    text-align: center;
  }
  .home-text09 {
    text-align: center;
  }
  .home-container08 {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .home-text11 {
    text-align: center;
  }
  .home-text12 {
    text-align: center;
  }
  .home-container10 {
    align-items: center;
  }
  .home-text14 {
    text-align: center;
  }
  .home-text18 {
    text-align: center;
  }
  .home-container12 {
    flex-direction: column;
  }
  .home-container13 {
    flex-direction: column;
  }
}
