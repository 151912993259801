.c-t-a-container {
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: space-between;
}
.c-t-a-container1 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: flex-start;
}
.c-t-a-text {
  margin-right: var(--dl-space-space-halfunit);
}
.c-t-a-c-t-a-subtitle {
  font-size: 16px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 300;
  text-transform: lowercase;
  overflow-wrap: break-word;
}
.c-t-a-c-t-a-subtitle:hover {
  color: var(--dl-color-turquoise-default);
}
.c-t-a-root-class-name {
  flex: 1;
  align-self: flex-end;
}
.c-t-a-root-class-name1 {
  flex: 1;
}
@media (max-width: 767px) {
  .c-t-a-c-t-a-subtitle {
    display: none;
  }
}
