.c-t-a1-container {
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: row;
  justify-content: space-between;
}
.c-t-a1-container1 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.c-t-a1-text {
  margin-right: var(--dl-space-space-halfunit);
}
.c-t-a1-c-t-a-subtitle {
  font-size: 16px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 300;
  text-transform: lowercase;
  overflow-wrap: break-word;
}
.c-t-a1-c-t-a-subtitle:hover {
  color: var(--dl-color-turquoise-default);
}

@media (max-width: 767px) {
  .c-t-a1-c-t-a-subtitle {
    display: none;
  }
}
