.style3-style1 {
  width: 50%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.style3-header {
  flex: 0 0 auto;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  position: relative;
  max-width: 400px;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.style3-container {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.style3-container1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.style3-store-banner {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.style3-root-class-name {
  margin: var(--dl-space-space-halfunit);
  align-self: flex-start;
}
@media (max-width: 479px) {
  .style3-header {
    max-width: 400px;
  }
}
