.feature-card-container {
  width: 320px;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.feature-card-image {
  width: var(--dl-size-size-doubleunit);
  height: var(--dl-size-size-doubleunit);
  object-fit: cover;
}
.feature-card-text {
  margin-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
}
.feature-card-text1 {
  text-align: center;
}
