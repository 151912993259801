.banner-container {
  width: 100%;
  display: flex;
  /* overflow: auto; */

  /* min-height: 100vh; */
  justify-content: center;
  /* align-items: center; */
  flex-direction: row;
  /* background-color: #d9d9d9; */
}
