.style2-style1 {
  width: 60%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
}
.style2-header {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  z-index: 100;
  position: relative;
  max-width: 600px;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.style2-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.style2-store-banner {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.style2-root-class-name {
  margin: var(--dl-space-space-halfunit);
  align-self: flex-start;
}
@media (max-width: 479px) {
  .style2-root-class-name {
    width: auto;
  }
}
