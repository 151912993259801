.feature-card4-feature-card {
  width: 100%;
  margin: var(--dl-space-space-unit);
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 400px;
  box-shadow: 5px 8.5px 34px 0px rgba(10, 3, 89, 0.1);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  padding-bottom: 1rem;
  justify-content: flex-start;
  background-color: #ffffff;
  text-align: center;
}
.feature-card4-image {
  width: 100px;
  max-width: 5rem;
  object-fit: cover;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.feature-card4-text {
  font-weight: 600;
}
.feature-card4-text1 {
  color: var(--dl-color-gray-700);
  padding: var(--dl-space-space-unit);
  text-align: center;
}
.feature-card4-button {
  color: var(--dl-color-gray-white);
  min-width: 35%;
  text-align: center;
  padding-top: var(--dl-space-space-halfunit);
  border-width: 0px;
  border-radius: 40px;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: #4a7eff;
}
.feature-card4-root-class-name {
  width: 100%;
}
.feature-card4-root-class-name1 {
  max-width: 400px;
}
.feature-card4-root-class-name2 {
  max-width: 400px;
}
.feature-card4-root-class-name3 {
  max-width: 400px;
}
.feature-card4-root-class-name4 {
  max-width: 400px;
}
@media (max-width: 479px) {
  .feature-card4-text {
    font-style: normal;
    font-weight: 600;
  }
}
