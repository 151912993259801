.feature-card1-feature-card {
  width: 100%;
  margin: var(--dl-space-space-doubleunit);
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: 420px;
  box-shadow: 0px 8.5px 34px 0px rgba(10, 3, 89, 0.1);
  min-height: 420px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
}
.feature-card1-icon {
  fill: #595959;
  width: 2rem;
  height: 2rem;
  margin-bottom: var(--dl-space-space-unit);
}
.feature-card1-text {
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
.feature-card1-text1 {
  color: var(--dl-color-gray-700);
  text-align: center;
}
@media (max-width: 479px) {
  .feature-card1-text {
    font-style: normal;
    font-weight: 600;
  }
}
